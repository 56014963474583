// Import Firebase ve Firestore modülleri
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase yapılandırması
const firebaseConfig = {
  apiKey: "AIzaSyAasrMimc82coBLxvTqSz0j_tXh1AQmuVA",
  authDomain: "mmpi-project.firebaseapp.com",
  projectId: "mmpi-project",
  storageBucket: "mmpi-project.appspot.com",
  messagingSenderId: "1008641243465",
  appId: "1:1008641243465:web:e7cc6cfd47f6e590f94298",
  measurementId: "G-D2VCN2CPJQ",
};

// Firebase'i başlat
const app = initializeApp(firebaseConfig);

// Firestore veritabanını başlat
const db = getFirestore(app);

export { app, db };
