import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Chart as ChartJS, registerables } from "chart.js";
import {
  mQuestionValues,
  mLValues,
  mFValues,
  mKValues,
  mHsValues,
  mDValues,
  mHyValues,
  mPdValues,
  mMfValues,
  mPaValues,
  mPtValues,
  mScValues,
  mMaValues,
  mSiValues,
} from "../values/ManValues";
import {
  wQuestionValues,
  wLValues,
  wFValues,
  wKValues,
  wHsValues,
  wDValues,
  wHyValues,
  wPdValues,
  wMfValues,
  wPaValues,
  wPtValues,
  wScValues,
  wMaValues,
  wSiValues,
} from "../values/WomanValues";

import { saveResultsToFirestore } from "../firebase/firebaseFunctions";

ChartJS.register(...registerables);

const Graph = () => {
  const [gender, setGender] = useState("man");
  const [age, setAge] = useState(""); // Yaş durumu
  const [consentGiven, setConsentGiven] = useState(false);


  const gradientClass =
    gender === "man"
      ? "bg-gradient-to-r from-blue-200 to-green-200"
      : "bg-gradient-to-r from-pink-200 to-yellow-200";

  const [values, setValues] = useState({
    "?": "",
    L: "",
    F: "",
    K: "",
    HS: "",
    D: "",
    HY: "",
    PD: "",
    MF: "",
    PA: "",
    PT: "",
    SC: "",
    MA: "",
    SI: "",
  });

  const [showScores, setShowScores] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [
      "?",
      "L",
      "F",
      "K",
      "HS",
      "D",
      "HY",
      "PD",
      "MF",
      "PA",
      "PT",
      "SC",
      "MA",
      "SI",
    ],
    dataValues: Array(14).fill(0),
  });
  const [name, setName] = useState("");
  const [showName, setShowName] = useState(false);

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value === "" ? "" : parseInt(value, 10) || 0,
    }));
  };

  const currentQuestionValues =
    gender === "man" ? mQuestionValues : wQuestionValues;
  const currentLValues = gender === "man" ? mLValues : wLValues;
  const currentFValues = gender === "man" ? mFValues : wFValues;
  const currentKValues = gender === "man" ? mKValues : wKValues;
  const currentHsValues = gender === "man" ? mHsValues : wHsValues;
  const currentDValues = gender === "man" ? mDValues : wDValues;
  const currentHyValues = gender === "man" ? mHyValues : wHyValues;
  const currentPdValues = gender === "man" ? mPdValues : wPdValues;
  const currentMfValues = gender === "man" ? mMfValues : wMfValues;
  const currentPaValues = gender === "man" ? mPaValues : wPaValues;
  const currentPtValues = gender === "man" ? mPtValues : wPtValues;
  const currentScValues = gender === "man" ? mScValues : wScValues;
  const currentMaValues = gender === "man" ? mMaValues : wMaValues;
  const currentSiValues = gender === "man" ? mSiValues : wSiValues;

  const mapUpdatedQuestionToGraphValue = (updatedQuestionValue) => {
    return currentQuestionValues[updatedQuestionValue] || updatedQuestionValue;
  };

  const mapUpdatedLToGraphValue = (updatedLValue) => {
    return currentLValues[updatedLValue] || updatedLValue;
  };

  const mapUpdatedFToGraphValue = (updatedFValue) => {
    return currentFValues[updatedFValue] || updatedFValue;
  };

  const mapUpdatedKToGraphValue = (updatedKValue) => {
    return currentKValues[updatedKValue] || updatedKValue;
  };

  const mapUpdatedDToGraphValue = (updatedDValue) => {
    return currentDValues[updatedDValue] || updatedDValue;
  };

  const mapUpdatedHyToGraphValue = (updatedHyValue) => {
    return currentHyValues[updatedHyValue] || updatedHyValue;
  };

  const mapUpdatedMfToGraphValue = (updatedMfValue) => {
    return currentMfValues[updatedMfValue] || updatedMfValue;
  };

  const mapUpdatedPaToGraphValue = (updatedPaValue) => {
    return currentPaValues[updatedPaValue] || updatedPaValue;
  };

  const mapUpdatedSiToGraphValue = (updatedSiValue) => {
    return currentSiValues[updatedSiValue] || updatedSiValue;
  };

  const offset5k = {
    30: 15,
    29: 15,
    28: 14,
    27: 14,
    26: 13,
    25: 13,
    24: 12,
    23: 12,
    22: 11,
    21: 11,
    20: 10,
    19: 10,
    18: 9,
    17: 9,
    16: 8,
    15: 8,
    14: 7,
    13: 7,
    12: 6,
    11: 6,
    10: 5,
    9: 5,
    8: 4,
    7: 4,
    6: 3,
    5: 3,
    4: 2,
    3: 2,
    2: 1,
    1: 1,
    0: 0,
  };

  const offset4k = {
    30: 12,
    29: 12,
    28: 11,
    27: 11,
    26: 10,
    25: 10,
    24: 10,
    23: 9,
    22: 9,
    21: 8,
    20: 8,
    19: 8,
    18: 7,
    17: 7,
    16: 6,
    15: 6,
    14: 6,
    13: 5,
    12: 5,
    11: 4,
    10: 4,
    9: 4,
    8: 3,
    7: 3,
    6: 2,
    5: 2,
    4: 2,
    3: 2,
    2: 1,
    1: 1,
    0: 0,
  };

  const offset2k = {
    30: 6,
    29: 6,
    28: 6,
    27: 5,
    26: 5,
    25: 5,
    24: 5,
    23: 5,
    22: 4,
    21: 4,
    20: 4,
    19: 4,
    18: 4,
    17: 3,
    16: 3,
    15: 3,
    14: 3,
    13: 3,
    12: 2,
    11: 2,
    10: 2,
    9: 2,
    8: 2,
    7: 1,
    6: 1,
    5: 1,
    4: 1,
    3: 1,
    2: 0,
    1: 0,
    0: 0,
  };

  const mapUpdatedHsToGraphValue = (updatedHsValue) => {
    return currentHsValues[updatedHsValue] || updatedHsValue;
  };

  const getUpdatedHsValue = (kValue, hsValue) => {
    const offsetHs = offset5k[Math.round(kValue)];
    return offsetHs ? hsValue + offsetHs : hsValue;
  };

  const mapUpdatedPdToGraphValue = (updatedPdValue) => {
    return currentPdValues[updatedPdValue] || updatedPdValue;
  };

  const getUpdatedPdValue = (kValue, pdValue) => {
    const offsetPd = offset4k[Math.round(kValue)];
    return offsetPd ? pdValue + offsetPd : pdValue;
  };

  const mapUpdatedPtToGraphValue = (updatedPtValue) => {
    return currentPtValues[updatedPtValue] || updatedPtValue;
  };

  const getUpdatedPtValue = (kValue, ptValue) => {
    return ptValue + kValue;
  };

  const mapUpdatedScToGraphValue = (updatedScValue) => {
    return currentScValues[updatedScValue] || updatedScValue;
  };

  const getUpdatedScValue = (kValue, scValue) => {
    return scValue + kValue;
  };

  const mapUpdatedMaToGraphValue = (updatedMaValue) => {
    return currentMaValues[updatedMaValue] || updatedMaValue;
  };

  const getUpdatedMaValue = (kValue, maValue) => {
    const offsetMa = offset2k[Math.round(kValue)];
    return offsetMa ? maValue + offsetMa : maValue;
  };

  const handleCalculate = () => {
    const updatedValues = { ...values };

    Object.keys(updatedValues).forEach((key) => {
      if (updatedValues[key] === "") {
        updatedValues[key] = 0;
      } else {
        updatedValues[key] = parseInt(updatedValues[key], 10) || 0;
      }
    });

    // Map and calculate values
    updatedValues["?"] = mapUpdatedQuestionToGraphValue(updatedValues["?"]);
    updatedValues.L = mapUpdatedLToGraphValue(updatedValues.L);
    updatedValues.F = mapUpdatedFToGraphValue(updatedValues.F);
    updatedValues.D = mapUpdatedDToGraphValue(updatedValues.D);
    updatedValues.HY = mapUpdatedHyToGraphValue(updatedValues.HY);
    updatedValues.MF = mapUpdatedMfToGraphValue(updatedValues.MF);
    updatedValues.PA = mapUpdatedPaToGraphValue(updatedValues.PA);
    updatedValues.SI = mapUpdatedSiToGraphValue(updatedValues.SI);

    updatedValues.HS = getUpdatedHsValue(updatedValues.K, updatedValues.HS);
    updatedValues.HS = mapUpdatedHsToGraphValue(updatedValues.HS);
    updatedValues.PD = getUpdatedPdValue(updatedValues.K, updatedValues.PD);
    updatedValues.PD = mapUpdatedPdToGraphValue(updatedValues.PD);
    updatedValues.PT = getUpdatedPtValue(updatedValues.K, updatedValues.PT);
    updatedValues.PT = mapUpdatedPtToGraphValue(updatedValues.PT);
    updatedValues.SC = getUpdatedScValue(updatedValues.K, updatedValues.SC);
    updatedValues.SC = mapUpdatedScToGraphValue(updatedValues.SC);
    updatedValues.MA = getUpdatedMaValue(updatedValues.K, updatedValues.MA);
    updatedValues.MA = mapUpdatedMaToGraphValue(updatedValues.MA);

    updatedValues.K = mapUpdatedKToGraphValue(updatedValues.K);

    // Güncel değerleri grafikte göstermek için chartData state'ini güncelle
    const dataValues = Object.keys(updatedValues).map(
      (key) => updatedValues[key]
    );
    setChartData({
      labels: Object.keys(updatedValues),
      dataValues: dataValues,
    });
    setShowScores(updatedValues);
    // Tarihi dd-mm-yyyy formatında oluşturmak için bir fonksiyon
   const formatDate = (timestamp) => {
  // Unix timestamp'ini milisaniye olarak bir Date nesnesine çevir
  const date = new Date(timestamp);

  // Gün, Ay ve Yıl'ı al ve iki haneli olacak şekilde biçimlendir
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Aylar 0'dan başlar
  const year = date.getFullYear();

  // Saat, Dakika ve Saniyeyi al ve iki haneli olacak şekilde biçimlendir
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Tarih ve saati birleştir
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};


    // Hesaplama sırasında tarih formatını ekleyelim
    saveResultsToFirestore({
      gender,
      age,
      results: updatedValues,
      timestamp: formatDate(new Date()), // Tarihi dd-mm-yyyy formatında gönder
      consentGiven,
    });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value); // Yaşı güncelle
  };

  const handleReset = () => {
    setValues({
      "?": "",
      L: "",
      F: "",
      K: "",
      HS: "",
      D: "",
      HY: "",
      PD: "",
      MF: "",
      PA: "",
      PT: "",
      SC: "",
      MA: "",
      SI: "",
    });
    setChartData({
      labels: [
        "?",
        "L",
        "F",
        "K",
        "HS",
        "D",
        "HY",
        "PD",
        "MF",
        "PA",
        "PT",
        "SC",
        "MA",
        "SI",
      ],
      dataValues: Array(14).fill(0),
    });
    setShowScores(showScores);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById("pdfContent");
    if (!input) {
      console.error("Element with id 'pdfContent' not found.");
      return;
    }

    html2canvas(input, { scale: 7 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();

        const imgWidth = pdf.internal.pageSize.getWidth() - 4;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const pageHeight = pdf.internal.pageSize.getHeight() - 4;
        const heightRatio = imgHeight > pageHeight ? pageHeight / imgHeight : 1;
        const finalWidth = imgWidth * heightRatio;
        const finalHeight = imgHeight * heightRatio;

        const x = (pdf.internal.pageSize.getWidth() - finalWidth) / 2;
        const y = 1;

        pdf.addImage(imgData, "PNG", x, y, finalWidth, finalHeight);
        pdf.save(`${name || "grafik"}.pdf`);
      })
      .catch((err) => {
        console.error("Error generating PDF:", err);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center mx-3 my-8 font-sans">
    {/* Bilgilendirme Kutusu */}
<div className="bg-purple-100 border-l-4 border-purple-500 text-purple-700 p-3 mb-3 rounded-md max-w-3xl shadow-md">
  <h6 className="font-semibold text-base text-center mb-2">
    🎉 <strong>MMPI Hesaplama'ya Hoş Geldiniz!</strong>
  </h6>
  <p className="text-xs text-center mb-3">
    <strong>MMPI Testi Hesaplama</strong> ve{" "}
    <strong>Psikolojik Test Analizi</strong> için tasarlanan bu platform,
    kullanıcıların kişilik özelliklerini ve psikolojik durumlarını
    değerlendirmesine yardımcı olur. Test sonuçlarınızı kolayca
    hesaplayabilir, <strong>detaylı grafikler</strong> oluşturabilir ve sonuçları{" "}
    <strong>PDF formatında indirebilirsiniz</strong>. 😊
  </p>

  {/* Türkiye Standartları Bilgilendirme Kutusu */}
  <div className="flex items-center bg-purple-50 border-l-4 border-purple-300 text-purple-600 p-2 rounded-md mb-3">
    <div className="flex-shrink-0">
      <svg
        className="w-5 h-5 text-purple-500"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 15h-2v-2h2zm0-4h-2V7h2z" />
      </svg>
    </div>
    <div className="ml-2">
      <p className="text-xs font-semibold">📌 <strong>Bilgilendirme:</strong></p>
      <p className="text-xs mt-1">
        Tüm hesaplamalar, <strong>Türkiye standartlarına</strong> uygun şekilde,{" "}
        <strong>yaygın olarak kabul gören MMPI Değerlendirme Rehberi</strong> baz alınarak
        gerçekleştirilmektedir.
      </p>
    </div>
  </div>

  {/* Gizlilik ve Veri İşleme Bilgilendirme Kutusu */}
  <div className="flex items-center bg-purple-50 border-l-4 border-purple-300 text-purple-600 p-2 rounded-md mb-3">
    <div className="flex-shrink-0">
      <svg
        className="w-5 h-5 text-purple-500"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 15h-2v-2h2zm0-4h-2V7h2z" />
      </svg>
    </div>
    <div className="ml-2">
      <p className="text-xs font-semibold">🔒 <strong>Gizlilik ve Veri İşleme İlkeleri:</strong></p>
      <p className="text-xs mt-1">
        Bu platformda yalnızca aşağıdaki veriler anonim olarak işlenir:
      </p>
      <div
        className="mt-1 p-2 bg-purple-50 border rounded-md text-xs text-purple-700"
        style={{ opacity: 0.95 }}
      >
        <p><strong>Cinsiyet:</strong> Erkek/Kadın</p>
        <p><strong>Yaş</strong></p>
        <p><strong>Test yanıtları:</strong> Doğru-yanlış cevaplar</p>
        <p><strong>Hesaplanmış skorlar</strong></p>
      </div>
      <p className="text-xs mt-2">
        Veriler yalnızca <strong>analiz ve grafik oluşturma</strong> amacıyla kullanılır.{" "}
        <strong>Kişisel bilgiler</strong> (isim, iletişim bilgisi vb.){" "}
        <strong>saklanmaz veya paylaşılmaz</strong>. Etik değerlerimize bağlıyız! 💜
      </p>
    </div>
  </div>

  {/* Kullanım Rehberi */}
  <div className="p-2 border rounded-md border-purple-300 bg-purple-50 mb-3">
    <h6 className="font-semibold text-xs text-center">🛠️ <strong>Nasıl Kullanılır?</strong></h6>
    <p className="text-xs text-center mt-1">
      Ham puan tablosu üzerinden hesaplama yapmak için menüden{" "}
      <strong>"Ham Puan Üzerinden"</strong> seçeneğini; <br />
      Test Sonuçları (doğru-yanlış sayıları) üzerinden hesaplama yapmak
      için{" "}
      <strong>"Test Sonuçları Üzerinden"</strong> seçeneğini tercih ediniz.
    </p>
    <p className="text-xs text-center mt-1">
      📊 Sonuçlarınızı <strong>grafikler halinde inceleyebilir</strong> ve{" "}
      <strong>PDF formatında indirebilirsiniz</strong>. 🚀
    </p>
  </div>

  {/* Rıza Çekme ve İletişim */}
  <div className="p-2 border rounded-md border-purple-300 bg-purple-50">
    <h6 className="font-semibold text-xs text-center">📧 <strong>İletişim ve Rıza Çekme</strong></h6>
    <p className="text-xs text-center mt-1">
      Verilerinizin işlenmesiyle ilgili rızanızı geri çekmek isterseniz
      bizimle iletişime geçebilirsiniz:{" "}
      <a
        href="mailto:info@mmpihesaplama.com"
        className="text-purple-700 font-semibold underline"
      >
        info@mmpihesaplama.com
      </a>
      .
    </p>
  </div>
</div>









      <div
        className={`p-4 ${gradientClass} rounded-md shadow-md w-full max-w-3xl mb-8 overflow-y-auto max-h-[500px]`}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4 p-4">
          MMPI Ham Puan Tablosu
        </h2>
        <div className="bg-gray-200 bg-opacity-50 rounded-md p-2 text-gray-700 w-4/5 mx-auto mb-4">
          <h6 className="text-xs">
            K değerleri eklenmemiş ham puanları giriniz.
          </h6>
        </div>
        <div className="bg-gray-200 bg-opacity-40 rounded-md p-2 text-gray-700 w-4/5 mx-auto mb-4">
          <h6 className="text-xs">
            ? Parametresi için ara değerler yaklaşık olarak hesaplanmaktadır.
          </h6>
        </div>
        {/* Gender and Age Selection */}
        <div className="mb-6 flex flex-col items-center">
          <div className="flex items-center gap-4">
            {/* Gender Selection */}
            <div className="flex flex-col items-center">
              <label htmlFor="gender-select" className="font-semibold mb-1">
                Cinsiyet
              </label>
              <select
                id="gender-select"
                value={gender}
                onChange={handleGenderChange}
                className="p-2 w-32 border rounded-md text-center"
              >
                <option value="man">Erkek</option>
                <option value="woman">Kadın</option>
              </select>
            </div>

            {/* Age Input */}
            <div className="flex flex-col items-center">
              <label htmlFor="age-input" className="font-semibold mb-1">
                Yaş
              </label>
              <input
                id="age-input"
                type="number"
                value={age}
                onChange={handleAgeChange}
                className="p-2 w-32 text-center border rounded-md"
              />
            </div>
          </div>
        </div>

        {/* Ham Puan Giriş Kutuları */}
        <div className="flex flex-col items-center gap-4 mt-4">
          {Object.keys(values).map((key, index) => {
            let labelPrefix = "";
            if (index >= 4 && index !== Object.keys(values).length - 1) {
              labelPrefix = `${index - 3}-`;
            } else if (index === Object.keys(values).length - 1) {
              labelPrefix = `0-`;
            }

            return (
              <div
                key={key}
                className="flex justify-center items-center p-2 bg-white border border-gray-300 rounded-md w-5/6 md:w-3/4"
              >
                <label className="w-1/3 text-center font-medium text-gray-800 text-m">
                  {labelPrefix}
                  {key} Ham Puanı
                </label>
                <input
                  type="number"
                  name={key}
                  value={values[key]}
                  onChange={handleInputChange}
                  className="w-16 text-center border rounded-md p-1 text-gray-700 bg-gray-100 focus:outline-none"
                  style={{
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                  }}
                />
              </div>
            );
          })}
        </div>

        {/* Ad Soyad Giriş Kutusu */}
        <div className="bg-white border border-gray-300 rounded-md p-2 mt-5 flex flex-col items-center w-4/5 md:w-2/3 mx-auto">
          <span className="text-gray-800 font-medium text-base">Ad Soyad</span>
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            className="w-full max-w-xs border rounded-md p-1 mt-2 text-gray-700 bg-gray-100 focus:outline-none"
          />
        </div>

        <div className="flex flex-col items-center gap-4 mt-5">
  {/* Açık Rıza Kutusu */}
<div className="flex flex-col items-center mb-5">
  <label htmlFor="consent-checkbox" className="flex items-center text-sm text-gray-800 bg-gray-100 p-3 rounded-lg shadow-md border border-gray-300 w-4/5">
    <input
      id="consent-checkbox"
      type="checkbox"
      onChange={(e) => setConsentGiven(e.target.checked)}
      className="mr-3 w-4 h-4 accent-purple-600 focus:ring focus:ring-purple-300"
    />
    <span className="text-gray-700">
    Verilerimin <strong>anonim olarak işlenmesi</strong> ve analiz edilmesi için onay veriyorum.
    </span>
  </label>
  {!consentGiven && (
    <div className="mt-3 bg-red-100 text-red-700 border-l-4 border-red-500 p-3 rounded-md shadow-md w-4/5">
      <p className="text-sm font-semibold">
      Lütfen verilerinizin anonim olarak işlenmesine onay vermek için kutucuğu işaretleyiniz.
      </p>
    </div>
  )}
</div>


  {/* Hesapla Butonu */}
  <button
    onClick={() => {
      if (!consentGiven) {
        alert("Lütfen verilerinizin anonim olarak işlenmesine onay vermek için kutucuğu işaretleyiniz.");
        return;
      }
      handleCalculate();
      setShowName(true);
    }}
    className="w-3/4 md:w-2/3 py-2 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-md transition duration-200 hover:opacity-90"
  >
    Hesapla
  </button>

  {/* Değerleri Sıfırla Butonu */}
  <button
    onClick={handleReset}
    className="w-3/4 md:w-2/3 py-2 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-md transition duration-200 hover:opacity-90"
  >
    Değerleri Sıfırla
  </button>

  {/* PDF İndir Butonu */}
  <button
    onClick={handleDownloadPDF}
    className="w-3/4 md:w-2/3 py-2 bg-gradient-to-r from-rose-400 to-pink-600 text-white rounded-md transition duration-200 hover:opacity-90"
  >
    Grafiği PDF Olarak İndir
  </button>
</div>

      </div>

      <div className="w-full max-w-5xl flex flex-col items-center gap-6 mb-16">
        {/* Grafik Box */}
        <div
          id="pdfContent"
          className="p-4 w-full mx-auto rounded-md shadow-md"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <h5 className="text-md font-semibold text-gray-800 mb-3">
            Ad Soyad: {name}
          </h5>

          {/* Sarı Arka Plan Kutusu ve Grafik Div'i */}
          <div className="relative w-full h-[400px] md:h-[450px]">
            <div
              className="absolute"
              style={{
                backgroundColor: "rgba(255, 215, 0, 0.4)",
                zIndex: 0,
                top: "43.2%",
                height: "28.8%",
                width: "100%",
              }}
            ></div>

            {/* LineChart Bileşeni */}
            <div className="relative w-full h-full">
              <LineChart chartData={chartData} gender={gender} />
            </div>
          </div>

          {/* K Eklenmiş Puanlar Tablosu */}
          {showName && (
            <div
              className="p-4 bg-purple-50 rounded-md shadow-md w-full flex flex-col items-center"
              style={{ marginTop: "24px" }}
            >
              <h5 className="text-lg font-semibold text-gray-800 mb-3">
                Hesaplanmış Puanlar
              </h5>
              <div className="flex flex-wrap justify-center gap-4">
                {Object.entries(showScores).map(([key, value], index) => {
                  let prefix = "";
                  if (
                    index >= 4 &&
                    index !== Object.entries(showScores).length - 1
                  ) {
                    prefix = `${index - 3}-`;
                  } else if (index === Object.entries(showScores).length - 1) {
                    prefix = "0-";
                  }
                  return (
                    <div
                      key={key}
                      className="flex items-center justify-center space-x-0.5 p-1 bg-white border border-gray-300 rounded-md shadow-sm w-28 h-8"
                    >
                      <span className="font-semibold text-gray-700">
                        {prefix}
                        {key}:
                      </span>
                      <span className="text-gray-600">{value}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Sayfanın Alt Kısmında Boşluk Bırakma */}
      <div className="h-2" />
    </div>
  );
};

const LineChart = ({ chartData, gender }) => {
  const modifiedLabels = chartData.labels.map((label, index) => {
    if (index >= 4 && index !== chartData.labels.length - 1) {
      return `${index - 3}-${label}`;
    } else if (index === chartData.labels.length - 1) {
      return `0-${label}`;
    } else {
      return label;
    }
  });

  const data = {
    labels: modifiedLabels,
    datasets: [
      {
        label: `MMPI Hesaplanmış Puanlar Grafiği (${
          gender === "man" ? "Erkek" : "Kadın"
        })`,
        data: chartData.dataValues,
        borderColor: "black",
        backgroundColor: "lightgrey",
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      y: {
        min: 0,
        max: 120,
        ticks: {
          stepSize: 10,
        },
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default Graph;
