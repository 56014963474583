import React, { useState } from 'react';
import Graph from './components/Graph';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import "./App.css";
import Graph2 from './components/Graph2';

function App() {
  const [currentSelect, setCurrentSelect] = useState("Test Sonuçları Üzerinden");


  return (
    <div className="App flex flex-col min-h-screen justify-between">
      <Navbar
        currentSelect={currentSelect}
        setCurrentSelect={setCurrentSelect}
      />
      
      {currentSelect === "Ham Puan Üzerinden" && <Graph />}
      {currentSelect === "Test Sonuçları Üzerinden" && <Graph2 />}
      <Footer />
    </div>
  );
}

export default App;

