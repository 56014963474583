import { ref, set, push, get } from "firebase/database";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
import database from "./firebaseDatabase"; // Realtime Database için
import { db } from "./firebaseConfig"; // Firestore için

// Firestore'a veri kaydetme fonksiyonu
export const saveResultsToFirestore = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "results"), {
      ...data,
      timestamp: Date.now(), // Kaydetme zamanı
    });
    console.log("Firestore'a veri başarıyla kaydedildi! ID:", docRef.id);
  } catch (error) {
    console.error("Firestore'a veri kaydedilirken hata:", error);
  }
};

// Firestore'dan veri çekme fonksiyonu
export const fetchResultsFromFirestore = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "results"));
    const results = [];
    querySnapshot.forEach((doc) => {
      results.push({ id: doc.id, ...doc.data() });
    });
    console.log("Firestore'dan veriler başarıyla alındı!");
    return results;
  } catch (error) {
    console.error("Firestore'dan veri çekilirken hata:", error);
  }
};

// Realtime Database'e veri kaydetme fonksiyonu
export const saveDataToRealtimeDB = (path, data) => {
  const dataRef = ref(database, path); // Verinin kaydedileceği yolu belirle
  const newDataRef = push(dataRef); // Her veri için benzersiz bir ID oluştur

  return set(newDataRef, {
    ...data,
    timestamp: Date.now(), // Kaydetme zamanı
  })
    .then(() => console.log("Realtime Database'e veri başarıyla kaydedildi!"))
    .catch((error) =>
      console.error("Realtime Database'e veri kaydedilirken hata:", error)
    );
};

// Realtime Database'den veri çekme fonksiyonu
export const fetchDataFromRealtimeDB = async (path) => {
  const dataRef = ref(database, path); // Verinin olduğu yolu belirle

  try {
    const snapshot = await get(dataRef); // Firebase'den veriyi al
    if (snapshot.exists()) {
      return snapshot.val(); // Veriyi döndür
    } else {
      console.log("Realtime Database'de veri bulunamadı.");
      return null;
    }
  } catch (error) {
    console.error("Realtime Database'den veri çekilirken hata:", error);
  }
};
