import React, { useState, useRef } from "react";
import { Line } from "react-chartjs-2";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Chart as ChartJS, registerables } from "chart.js";
import mQNumbers from "../values/ManQNumbers";
import wQNumbers from "../values/WomanQNumbers";
import {
  mQuestionValues,
  mLValues,
  mFValues,
  mKValues,
  mHsValues,
  mDValues,
  mHyValues,
  mPdValues,
  mMfValues,
  mPaValues,
  mPtValues,
  mScValues,
  mMaValues,
  mSiValues,
} from "../values/ManValues";
import {
  wQuestionValues,
  wLValues,
  wFValues,
  wKValues,
  wHsValues,
  wDValues,
  wHyValues,
  wPdValues,
  wMfValues,
  wPaValues,
  wPtValues,
  wScValues,
  wMaValues,
  wSiValues,
} from "../values/WomanValues";
import { saveResultsToFirestore } from "../firebase/firebaseFunctions"; // Firestore için


ChartJS.register(...registerables);

const Graph2 = () => {
  const [gender, setGender] = useState("man");
  const [age, setAge] = useState(""); // Yaş durumu
  const [consentGiven, setConsentGiven] = useState(false);
  const [responses, setResponses] = useState(Array(566).fill(null));
  const [scores, setScores] = useState({
    "?": 0,
    L: 0,
    F: 0,
    K: 0,
    HS: 0,
    D: 0,
    HY: 0,
    PD: 0,
    MF: 0,
    PA: 0,
    PT: 0,
    SC: 0,
    MA: 0,
    SI: 0,
  });
  const [name, setName] = useState("");
  const [showName, setShowName] = useState(false);

  const gradientClass =
  gender === "man"
    ? "bg-gradient-to-r from-blue-200 to-green-200"
    : "bg-gradient-to-r from-pink-200 to-yellow-200";

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    setResponses(Array(566).fill(null)); // Reset responses when gender changes
  };
  const handleAgeChange = (event) => {
    setAge(event.target.value); // Yaşı güncelle
  };

  const handleKeyDown = (event) => {
    const index = event.target.dataset.index;
    if (index !== undefined) {
      if (event.key === "1" || event.key === "D") {
        handleChange(index, "D");
        focusNextInput(Number(index) + 1);
      } else if (event.key === "2" || event.key === "Y") {
        handleChange(index, "Y");
        focusNextInput(Number(index) + 1);
      } else if (event.key === "0") {
        handleChange(index, null);
        focusNextInput(Number(index) + 1);
      }
    }
  };

  const handleChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index] = value;
    setResponses(newResponses);
  };

  const focusNextInput = (nextIndex) => {
    const nextInput = document.querySelector(
      `input[data-index="${nextIndex}"]`
    );
    if (nextInput) {
      nextInput.focus();
    }
  };

  const handleInputFocus = (index) => {
    const currentInput = document.querySelector(`input[data-index="${index}"]`);
    currentInput.value = "";
  };

  const handleInputChange = (event) => {
    const index = event.target.dataset.index;
    const value = event.target.value.toUpperCase();
    if (
      index !== undefined &&
      (value === "D" || value === "Y" || value === "")
    ) {
      handleChange(index, value === "" ? null : value);
      focusNextInput(Number(index) + 1);
    }
  };

    // Choose which values to use based on gender
const currentQuestionValues = gender === "man" ? mQuestionValues : wQuestionValues;
const currentQNumbers = gender === "man" ? mQNumbers : wQNumbers;
const currentLValues = gender === "man" ? mLValues : wLValues;
const currentFValues = gender === "man" ? mFValues : wFValues;
const currentKValues = gender === "man" ? mKValues : wKValues;
const currentHsValues = gender === "man" ? mHsValues : wHsValues;
const currentDValues = gender === "man" ? mDValues : wDValues;
const currentHyValues = gender === "man" ? mHyValues : wHyValues;
const currentPdValues = gender === "man" ? mPdValues : wPdValues;
const currentMfValues = gender === "man" ? mMfValues : wMfValues;
const currentPaValues = gender === "man" ? mPaValues : wPaValues;
const currentPtValues = gender === "man" ? mPtValues : wPtValues;
const currentScValues = gender === "man" ? mScValues : wScValues;
const currentMaValues = gender === "man" ? mMaValues : wMaValues;
const currentSiValues = gender === "man" ? mSiValues : wSiValues;

  const mapUpdatedQuestionToGraphValue = (updatedQuestionValue) => {
    return currentQuestionValues[updatedQuestionValue] || updatedQuestionValue;
  };

  const mapUpdatedLToGraphValue = (updatedLValue) => {
    return currentLValues[updatedLValue] || updatedLValue;
  };

  const mapUpdatedFToGraphValue = (updatedFValue) => {
    return currentFValues[updatedFValue] || updatedFValue;
  };

  const mapUpdatedKToGraphValue = (updatedKValue) => {
    return currentKValues[updatedKValue] || updatedKValue;
  };

  const mapUpdatedDToGraphValue = (updatedDValue) => {
    return currentDValues[updatedDValue] || updatedDValue;
  };

  const mapUpdatedHyToGraphValue = (updatedHyValue) => {
    return currentHyValues[updatedHyValue] || updatedHyValue;
  };

  const mapUpdatedMfToGraphValue = (updatedMfValue) => {
    return currentMfValues[updatedMfValue] || updatedMfValue;
  };

  const mapUpdatedPaToGraphValue = (updatedPaValue) => {
    return currentPaValues[updatedPaValue] || updatedPaValue;
  };

  const mapUpdatedSiToGraphValue = (updatedSiValue) => {
    return currentSiValues[updatedSiValue] || updatedSiValue;
  };

  const offset5k = {
    30: 15,
    29: 15,
    28: 14,
    27: 14,
    26: 13,
    25: 13,
    24: 12,
    23: 12,
    22: 11,
    21: 11,
    20: 10,
    19: 10,
    18: 9,
    17: 9,
    16: 8,
    15: 8,
    14: 7,
    13: 7,
    12: 6,
    11: 6,
    10: 5,
    9: 5,
    8: 4,
    7: 4,
    6: 3,
    5: 3,
    4: 2,
    3: 2,
    2: 1,
    1: 1,
    0: 0,
  };

  const offset4k = {
    30: 12,
    29: 12,
    28: 11,
    27: 11,
    26: 10,
    25: 10,
    24: 10,
    23: 9,
    22: 9,
    21: 8,
    20: 8,
    19: 8,
    18: 7,
    17: 7,
    16: 6,
    15: 6,
    14: 6,
    13: 5,
    12: 5,
    11: 4,
    10: 4,
    9: 4,
    8: 3,
    7: 3,
    6: 2,
    5: 2,
    4: 2,
    3: 2,
    2: 1,
    1: 1,
    0: 0,
  };

  const offset2k = {
    30: 6,
    29: 6,
    28: 6,
    27: 5,
    26: 5,
    25: 5,
    24: 5,
    23: 5,
    22: 4,
    21: 4,
    20: 4,
    19: 4,
    18: 4,
    17: 3,
    16: 3,
    15: 3,
    14: 3,
    13: 3,
    12: 2,
    11: 2,
    10: 2,
    9: 2,
    8: 2,
    7: 1,
    6: 1,
    5: 1,
    4: 1,
    3: 1,
    2: 0,
    1: 0,
    0: 0,
  };

  const mapUpdatedHsToGraphValue = (updatedHsValue) => {
    return currentHsValues[updatedHsValue] || updatedHsValue;
  };

  const getUpdatedHsValue = (kValue, hsValue) => {
    const offsetHs = offset5k[Math.round(kValue)];
    return offsetHs ? hsValue + offsetHs : hsValue;
  };

  const mapUpdatedPdToGraphValue = (updatedPdValue) => {
    return currentPdValues[updatedPdValue] || updatedPdValue;
  };

  const getUpdatedPdValue = (kValue, pdValue) => {
    const offsetPd = offset4k[Math.round(kValue)];
    return offsetPd ? pdValue + offsetPd : pdValue;
  };

  const mapUpdatedPtToGraphValue = (updatedPtValue) => {
    return currentPtValues[updatedPtValue] || updatedPtValue;
  };

  const getUpdatedPtValue = (kValue, ptValue) => {
    return ptValue + kValue;
  };

  const mapUpdatedScToGraphValue = (updatedScValue) => {
    return currentScValues[updatedScValue] || updatedScValue;
  };

  const getUpdatedScValue = (kValue, scValue) => {
    return scValue + kValue;
  };

  const mapUpdatedMaToGraphValue = (updatedMaValue) => {
    return currentMaValues[updatedMaValue] || updatedMaValue;
  };

  const getUpdatedMaValue = (kValue, maValue) => {
    const offsetMa = offset2k[Math.round(kValue)];
    return offsetMa ? maValue + offsetMa : maValue;
  };




const calculateScores = (responses) => {
  if (responses.length !== 566) {
    console.error("Responses array length mismatch.");
    return;
  }

  const newScores = {}; // Yeni puanları saklamak için bir nesne

  // Her soru için puanları hesapla
  for (const [key, value] of Object.entries(currentQNumbers)) {
    newScores[key] = value.questions.reduce((total, qObj) => {
      const response = responses[qObj.question - 1]; // Sıfırdan başlayarak erişim

      if (qObj.condition === "0" && response === "0") {
        return total + 1;
      }
      if (qObj.condition === "Y" && response === "Y") {
        return total + 1;
      }
      if (qObj.condition === "D" && response === "D") {
        return total + 1;
      }
      return total; // Hiçbir koşul sağlanmazsa toplamı değiştirme
    }, 0);
  }

  // Boş yanıtların sayısını hesapla ve kaydet
  newScores["?"] = mapUpdatedQuestionToGraphValue(
    responses.filter((response) => response === null).length
  );

  // Diğer puanları haritalama
  newScores["L"] = mapUpdatedLToGraphValue(newScores.L);
  newScores["F"] = mapUpdatedFToGraphValue(newScores.F);
  newScores["D"] = mapUpdatedDToGraphValue(newScores.D);
  newScores["HY"] = mapUpdatedHyToGraphValue(newScores.HY);
  newScores["MF"] = mapUpdatedMfToGraphValue(newScores.MF);
  newScores["PA"] = mapUpdatedPaToGraphValue(newScores.PA);
  newScores["SI"] = mapUpdatedSiToGraphValue(newScores.SI);

  // HS, PD, PT, SC, MA ve K için güncellemeleri hesapla
  const updatedHsValue = getUpdatedHsValue(newScores["K"], newScores["HS"]);
  newScores["HS"] = mapUpdatedHsToGraphValue(updatedHsValue);
  const updatedPdValue = getUpdatedPdValue(newScores["K"], newScores["PD"]);
  newScores["PD"] = mapUpdatedPdToGraphValue(updatedPdValue);
  const updatedPtValue = getUpdatedPtValue(newScores["K"], newScores["PT"]);
  newScores["PT"] = mapUpdatedPtToGraphValue(updatedPtValue);
  const updatedScValue = getUpdatedScValue(newScores["K"], newScores["SC"]);
  newScores["SC"] = mapUpdatedScToGraphValue(updatedScValue);
  const updatedMaValue = getUpdatedMaValue(newScores["K"], newScores["MA"]);
  newScores["MA"] = mapUpdatedMaToGraphValue(updatedMaValue);
  newScores["K"] = mapUpdatedKToGraphValue(newScores.K);

  // Sonuçları sıralayarak "?" en başta olacak şekilde düzenleme
  const sortedScores = Object.keys(newScores)
    .sort((a, b) => (a === "?" ? -1 : b === "?" ? 1 : 0))
    .reduce((acc, key) => {
      acc[key] = newScores[key];
      return acc;
    }, {});

  // Sonuçları ayarla
  setScores(sortedScores);
  
  // Tarihi dd-mm-yyyy formatında oluşturmak için bir fonksiyon
  const formatDate = (timestamp) => {
    // Unix timestamp'ini milisaniye olarak bir Date nesnesine çevir
    const date = new Date(timestamp);
  
    // Gün, Ay ve Yıl'ı al ve iki haneli olacak şekilde biçimlendir
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Aylar 0'dan başlar
    const year = date.getFullYear();
  
    // Saat, Dakika ve Saniyeyi al ve iki haneli olacak şekilde biçimlendir
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
  
    // Tarih ve saati birleştir
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  
  // Firebase'e sonuçları kaydet
  const dataToSave = {
    gender, // Cinsiyet
    age,
    scores: sortedScores, // Hesaplanmış puanlar
    responses, // Kullanıcı yanıtları
    timestamp: formatDate(new Date()), // Zaman damgası
    consentGiven,
  };

  saveResultsToFirestore(dataToSave)
    .then(() => console.log("Sonuçlar Firebase'e kaydedildi!"))
    .catch((error) => console.error("Firebase'e kaydetme hatası:", error));

};


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById("pdfContent");
    if (!input) {
      console.error("Element with id 'pdfContent' not found.");
      return;
    }

    html2canvas(input, { scale: 7 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();

        const imgWidth = pdf.internal.pageSize.getWidth() - 4;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const pageHeight = pdf.internal.pageSize.getHeight() - 4;
        const heightRatio = imgHeight > pageHeight ? pageHeight / imgHeight : 1;
        const finalWidth = imgWidth * heightRatio;
        const finalHeight = imgHeight * heightRatio;

        const x = (pdf.internal.pageSize.getWidth() - finalWidth) / 2;
        const y = 1;

        pdf.addImage(imgData, "PNG", x, y, finalWidth, finalHeight);
        pdf.save(`${name || "grafik"}.pdf`);
      })
      .catch((err) => {
        console.error("Error generating PDF:", err);
      });
  };

  const handleReset = () => {
    setScores({
      "?": 0,
      L: 0,
      F: 0,
      K: 0,
      HS: 0,
      D: 0,
      HY: 0,
      PD: 0,
      MF: 0,
      PA: 0,
      PT: 0,
      SC: 0,
      MA: 0,
      SI: 0,
    });
    setResponses(Array(566).fill(null));
  };

  const LineChart = () => {
    const chartRef = useRef(null);
  
    const labels = [
      "?",
      "L",
      "F",
      "K",
      "HS",
      "D",
      "HY",
      "PD",
      "MF",
      "PA",
      "PT",
      "SC",
      "MA",
      "SI",
    ];
  
  
    const modifiedLabels = labels.map((label, index) => {
      if (index >= 4 && index !== labels.length - 1) {
        return `${index - 3}-${label}`; 
      } else if (index === labels.length - 1) {
        return `0-${label}`;
      } else {
        return label; 
      }
    });
  
    const dataValues = labels.map((label) => scores[label]);
  
    const data = {
      labels: modifiedLabels, // Güncellenmiş label'ları burada kullanıyoruz
      datasets: [
        {
          label: `MMPI Hesaplanmış Puanlar Grafiği (${gender === "man" ? "Erkek" : "Kadın"})`,
          data: dataValues,
          borderColor: "black",
          backgroundColor: "lightgrey",
          borderWidth: 1,
          fill: false,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
      },
      scales: {
        x: {
          grid: {
            display: true,
            color: "rgba(0, 0, 0, 0.1)",
          },
        },
        y: {
          min: 0,
          max: 120,
          ticks: {
            stepSize: 10,
          },
          grid: {
            display: true,
            color: "rgba(0, 0, 0, 0.1)",
          },
        },
      },
    };
  
    return (
      <div className="chart-container" style={{ width: "100%", height: "100%" }}>
        <div
          className="yellow-background"
          style={{ backgroundColor: "rgba(255, 255, 0, 0.8)" }}
        ></div>
        <Line ref={chartRef} data={data} options={options} />
      </div>
    );
  };
  

  return (
    <div className="flex flex-col items-center justify-center mx-3 my-8 font-sans">
     {/* Bilgilendirme Kutusu */}
<div className="bg-purple-100 border-l-4 border-purple-500 text-purple-700 p-3 mb-3 rounded-md max-w-3xl shadow-md">
  <h6 className="font-semibold text-base text-center mb-2">
    🎉 <strong>MMPI Hesaplama'ya Hoş Geldiniz!</strong>
  </h6>
  <p className="text-xs text-center mb-3">
    <strong>MMPI Testi Hesaplama</strong> ve{" "}
    <strong>Psikolojik Test Analizi</strong> için tasarlanan bu platform,
    kullanıcıların kişilik özelliklerini ve psikolojik durumlarını
    değerlendirmesine yardımcı olur. Test sonuçlarınızı kolayca
    hesaplayabilir, <strong>detaylı grafikler</strong> oluşturabilir ve sonuçları{" "}
    <strong>PDF formatında indirebilirsiniz</strong>. 😊
  </p>

  {/* Türkiye Standartları Bilgilendirme Kutusu */}
  <div className="flex items-center bg-purple-50 border-l-4 border-purple-300 text-purple-600 p-2 rounded-md mb-3">
    <div className="flex-shrink-0">
      <svg
        className="w-5 h-5 text-purple-500"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 15h-2v-2h2zm0-4h-2V7h2z" />
      </svg>
    </div>
    <div className="ml-2">
      <p className="text-xs font-semibold">📌 <strong>Bilgilendirme:</strong></p>
      <p className="text-xs mt-1">
        Tüm hesaplamalar, <strong>Türkiye standartlarına</strong> uygun şekilde,{" "}
        <strong>yaygın olarak kabul gören MMPI Değerlendirme Rehberi</strong> baz alınarak
        gerçekleştirilmektedir.
      </p>
    </div>
  </div>

  {/* Gizlilik ve Veri İşleme Bilgilendirme Kutusu */}
  <div className="flex items-center bg-purple-50 border-l-4 border-purple-300 text-purple-600 p-2 rounded-md mb-3">
    <div className="flex-shrink-0">
      <svg
        className="w-5 h-5 text-purple-500"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 15h-2v-2h2zm0-4h-2V7h2z" />
      </svg>
    </div>
    <div className="ml-2">
      <p className="text-xs font-semibold">🔒 <strong>Gizlilik ve Veri İşleme İlkeleri:</strong></p>
      <p className="text-xs mt-1">
        Bu platformda yalnızca aşağıdaki veriler anonim olarak işlenir:
      </p>
      <div
        className="mt-1 p-2 bg-purple-50 border rounded-md text-xs text-purple-700"
        style={{ opacity: 0.95 }}
      >
        <p><strong>Cinsiyet:</strong> Erkek/Kadın</p>
        <p><strong>Yaş</strong></p>
        <p><strong>Test yanıtları:</strong> Doğru-yanlış cevaplar</p>
        <p><strong>Hesaplanmış skorlar</strong></p>
      </div>
      <p className="text-xs mt-2">
        Veriler yalnızca <strong>analiz ve grafik oluşturma</strong> amacıyla kullanılır.{" "}
        <strong>Kişisel bilgiler</strong> (isim, iletişim bilgisi vb.){" "}
        <strong>saklanmaz veya paylaşılmaz</strong>. Etik değerlerimize bağlıyız! 💜
      </p>
    </div>
  </div>

  {/* Kullanım Rehberi */}
  <div className="p-2 border rounded-md border-purple-300 bg-purple-50 mb-3">
    <h6 className="font-semibold text-xs text-center">🛠️ <strong>Nasıl Kullanılır?</strong></h6>
    <p className="text-xs text-center mt-1">
      Ham puan tablosu üzerinden hesaplama yapmak için menüden{" "}
      <strong>"Ham Puan Üzerinden"</strong> seçeneğini; <br />
      Test Sonuçları (doğru-yanlış sayıları) üzerinden hesaplama yapmak
      için{" "}
      <strong>"Test Sonuçları Üzerinden"</strong> seçeneğini tercih ediniz.
    </p>
    <p className="text-xs text-center mt-1">
      📊 Sonuçlarınızı <strong>grafikler halinde inceleyebilir</strong> ve{" "}
      <strong>PDF formatında indirebilirsiniz</strong>. 🚀
    </p>
  </div>

  {/* Rıza Çekme ve İletişim */}
  <div className="p-2 border rounded-md border-purple-300 bg-purple-50">
    <h6 className="font-semibold text-xs text-center">📧 <strong>İletişim ve Rıza Çekme</strong></h6>
    <p className="text-xs text-center mt-1">
      Verilerinizin işlenmesiyle ilgili rızanızı geri çekmek isterseniz
      bizimle iletişime geçebilirsiniz:{" "}
      <a
        href="mailto:info@mmpihesaplama.com"
        className="text-purple-700 font-semibold underline"
      >
        info@mmpihesaplama.com
      </a>
      .
    </p>
  </div>
</div>


      {/* Test Cevapları Kutusu */}
      <div className={`p-4 ${gradientClass} rounded-md shadow-md w-full max-w-3xl mb-8 overflow-y-auto max-h-[500px]`}>
  <div className="bg-purple-300 bg-opacity-50 rounded-md p-2 text-gray-700 w-4/5 mx-auto mb-4">
    <p className="text-sm">
      <strong>Uyarı:</strong> Test
      sonuçlarında dil kaynaklı karakter hatası ile karşılaşmanız
      durumunda, lütfen sayfayı yenileyerek tekrar deneyiniz.
    </p>
  </div>
  <h2 className="text-xl font-semibold text-gray-800 mb-4">
    MMPI Test Cevapları
  </h2>
  <div className="bg-gray-200 bg-opacity-50 rounded-md p-2 text-gray-700 w-4/5 mx-auto mb-4">
    <h6 className="text-xs">DOĞRU (D) cevaplar için D veya 1,</h6>
    <h6 className="text-xs">YANLIŞ (Y) cevaplar için Y veya 2,</h6>
    <h6 className="text-xs">
      BOŞ cevaplar için soruyu atlayınız veya 0 giriniz.
    </h6>
  </div>
  <div className="bg-gray-200 bg-opacity-40 rounded-md p-2 text-gray-700 w-4/5 mx-auto mb-4">
    <h6 className="text-xs">
      ? Parametresi için ara değerler yaklaşık olarak hesaplanmaktadır.
    </h6>
  </div>
  {/* Gender and Age Selection */}
<div className="mb-6 flex flex-col items-center">
  <div className="flex items-center gap-4">
    {/* Gender Selection */}
    <div className="flex flex-col items-center">
      <label htmlFor="gender-select" className="font-semibold mb-1">
        Cinsiyet
      </label>
      <select
        id="gender-select"
        value={gender}
        onChange={handleGenderChange}
        className="p-2 w-32 border rounded-md text-center"
      >
        <option value="man">Erkek</option>
        <option value="woman">Kadın</option>
      </select>
    </div>

    {/* Age Input */}
    <div className="flex flex-col items-center">
      <label htmlFor="age-input" className="font-semibold mb-1">
        Yaş
      </label>
      <input
        id="age-input"
        type="number"
        value={age}
        onChange={handleAgeChange}
        className="p-2 w-32 text-center border rounded-md"
      />
    </div>
  </div>
</div>

  {/* Soru Kutuları */}
  <div className="flex flex-col items-center gap-2 mt-4">
    {responses.map((response, index) => (
      <div
        key={index}
        className="flex justify-center items-center p-2 bg-white border border-gray-300 rounded-md w-4/5 md:w-2/3"
      >
        {/* Soru Label ve Input Ortalanmış */}
        <label className="mr-4 font-medium text-gray-800 text-base">
          Soru {index + 1}
        </label>
        <input
          type="text"
          data-index={index}
          value={response || ""}
          onFocus={() => handleInputFocus(index)}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className="w-12 text-center text-gray-700 bg-gray-100 border rounded-md focus:outline-none"
          maxLength={1}
        />
      </div>
    ))}
  </div>

  {/* Ad Soyad Giriş Kutusu */}
  <div className="bg-white border border-gray-300 rounded-md p-2 mt-5 flex flex-col items-center w-4/5 md:w-2/3 mx-auto">
    <span className="text-gray-800 font-medium text-base">Ad Soyad</span>
    <input
      type="text"
      value={name}
      onChange={handleNameChange}
      className="w-full max-w-xs border rounded-md p-1 mt-2 text-gray-700 bg-gray-100 focus:outline-none"
    />
  </div>

  {/* Butonlar */}
  <div className="flex flex-col items-center gap-4 mt-5">
    {/* Açık Rıza Kutusu */}
<div className="flex flex-col items-center mb-5">
  <label htmlFor="consent-checkbox" className="flex items-center text-sm text-gray-800 bg-gray-100 p-3 rounded-lg shadow-md border border-gray-300 w-4/5">
    <input
      id="consent-checkbox"
      type="checkbox"
      onChange={(e) => setConsentGiven(e.target.checked)}
      className="mr-3 w-4 h-4 accent-purple-600 focus:ring focus:ring-purple-300"
    />
    <span className="text-gray-700">
      Verilerimin <strong>anonim olarak işlenmesi</strong> ve analiz edilmesi için onay veriyorum.
    </span>
  </label>
  {!consentGiven && (
    <div className="mt-3 bg-red-100 text-red-700 border-l-4 border-red-500 p-3 rounded-md shadow-md w-4/5">
      <p className="text-sm font-semibold">
      Lütfen verilerinizin anonim olarak işlenmesine onay vermek için kutucuğu işaretleyiniz.
      </p>
    </div>
  )}
</div>


     {/* Hesapla Butonu */}
  <button
    onClick={() => {
      if (!consentGiven) {
        alert("Lütfen verilerinizin anonim olarak işlenmesine onay vermek için kutucuğu işaretleyiniz.");
        return;
      }
      calculateScores(responses);
        setShowName(true);
    }}
    className="w-3/4 md:w-2/3 py-2 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-md transition duration-200 hover:opacity-90"
  >
    Hesapla
  </button>

    {/* Değerleri Sıfırla Butonu */}
    <button
      onClick={handleReset}
      className="w-3/4 md:w-2/3 py-2 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-md transition duration-200 hover:opacity-90"
    >
      Değerleri Sıfırla
    </button>

    {/* PDF İndir Butonu */}
    <button
      onClick={handleDownloadPDF}
      className="w-3/4 md:w-2/3 py-2 bg-gradient-to-r from-rose-400 to-pink-600 text-white rounded-md transition duration-200 hover:opacity-90"
    >
      Grafiği PDF Olarak İndir
    </button>
  </div>
</div>


      {/* Grafik ve K Eklenmiş Puanlar (Alt Kısma Taşındı) */}
      <div className="w-full max-w-5xl flex flex-col items-center gap-6 mb-16">
        {/* Grafik Box */}
        <div
          id="pdfContent"
          className="p-4 w-full mx-auto rounded-md shadow-md"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <h5 className="text-md font-semibold text-gray-800 mb-3">
            Ad Soyad: {name}
          </h5>

          {/* Sarı Arkaplan Kutusu ve Grafik Div'i */}
          <div className="relative w-full h-[400px] md:h-[450px]">
            <div
              className="absolute"
              style={{
                backgroundColor: "rgba(255, 215, 0, 0.4)",
                zIndex: 0,
                top: "43%",
                height: "29%",
                width: "100%",
              }}
            ></div>
            {/* LineChart Bileşeni */}
            <div className="relative w-full h-full">
              <LineChart />
            </div>
          </div>

          {/* K Eklenmiş Puanlar Tablosu */}
{showName && (
  <div
    className="p-4 bg-purple-50 rounded-md shadow-md w-full flex flex-col items-center"
    style={{ marginTop: "24px" }}
  >
    <h5 className="text-lg font-semibold text-gray-800 mb-3">
      Hesaplanmış Puanlar
    </h5>
    <div className="flex flex-wrap justify-center gap-4">
      {Object.entries(scores).map(([key, value], index) => {
        let prefix = ""; 
        if (index >= 4 && index !== Object.entries(scores).length - 1) {
          prefix = `${index - 3}-`; 
        } else if (index === Object.entries(scores).length - 1) {
          prefix = "0-"; 
        }
        return (
          <div
            key={key}
            className="flex items-center justify-center space-x-0.5 p-1 bg-white border border-gray-300 rounded-md shadow-sm w-28 h-8"
           
          >
            <span className="font-semibold text-gray-700">{prefix}{key}:</span>
            <span className="text-gray-600">{value}</span>
          </div>
        );
      })}
    </div>
  </div>
)}
        </div>
      </div>
      {/* Sayfanın Alt Kısmında Boşluk Bırakma */}
      <div className="h-2" />
    </div>
  );
};

export default Graph2;
